//======> Tornado UI Core Base <=======//
import './Base/Tornado';
import Tornado from './Base/Tornado';

document.addEventListener('DOMContentLoaded', event => {
    "use strict";

    //====> Hero Slider <====//
    Tornado.slider('.hero-slider', {
        items      : 1,     //===> Display Slides Count
        duration   : 8000,  //===> Autoplay Duration
        pagination : true,   //===> Show Dots Pagination
        controls   : true,   //===> Show Arrows
        animation  : "gallery",    //===> Animation Mode [carousel, gallery]
        speed      : 1000,           //===> Animation Speed
        infinite   : false,
    });

    //====> Carousel Slider <====//
    Tornado.getElements('.carousel-slider').forEach(element => {
        //====> Dynamic Options <====//
        var itemsNumber = element.getAttribute('data-items'),
            itemsSmall  = element.getAttribute('data-sm'),
            itemsMedium = element.getAttribute('data-md'),
            itemsLarge  = element.getAttribute('data-lg'),
            itemsXLarge = element.getAttribute('data-xl'),
            controls    = element.getAttribute('data-controls'),
            cssClass    = element.getAttribute('data-class') || 'carousel-slider-outer',
            pagination  = element.getAttribute('data-pagination');

        //====> Create Slider <====//
        Tornado.slider(element, {
            duration   : 7000,
            speed      : 700,
            items      : parseInt(itemsNumber) || 1,
            pagination : pagination ? true : false,
            controls   : controls ? true : false,
            uniuqClass : cssClass,
            responsive : {
                small  : { items: parseInt(itemsSmall)  || 2},
                medium : { items: parseInt(itemsMedium) || 3},
                large  : { items: parseInt(itemsLarge)  || 4} ,
                xlarge : { items: parseInt(itemsXLarge)  || 4} 
            }
        });
    });

    //====> Menu Slider <====//
    Tornado.getElements('.menu-section ul').forEach(element => {
        //====> Dynamic Options <====//
        var itemsNumber = element.getAttribute('data-items'),
            itemsSmall  = element.getAttribute('data-sm'),
            itemsMedium = element.getAttribute('data-md'),
            itemsLarge  = element.getAttribute('data-lg'),
            itemsXLarge = element.getAttribute('data-xl'),
            controls    = element.getAttribute('data-controls'),
            cssClass    = element.getAttribute('data-class') || 'carousel-slider-outer',
            pagination  = element.getAttribute('data-pagination');

        //====> Create Slider <====//
        Tornado.slider(element, {
            duration   : 7000,
            speed      : 700,
            items      : parseInt(itemsNumber) || 1,
            pagination : pagination ? true : false,
            controls   : controls ? true : false,
            uniuqClass : cssClass,
            responsive : {
                small  : { items: parseInt(itemsSmall)  || 2},
                medium : { items: parseInt(itemsMedium) || 3},
                large  : { items: parseInt(itemsLarge)  || 4} ,
                xlarge : { items: parseInt(itemsXLarge)  || 4} 
            }
        });
    });

    //====> Modals Timout <====//
    Tornado.getElements('.modal-box[data-timeout]').forEach(element => {
        var timeValue = parseInt(element.getAttribute('data-timeout'));
        setTimeout(timer => {
            //===> Activate the Popup <===//
            element.classList.add('active');
        }, timeValue);
    });
    
    //====> Activate Popups <====//
    Tornado.popup('.modal-box');
});